export function sendLeadBooking(form, params) {

    let submitButton = $(form).find(':submit');

    grecaptcha.ready(function () {
            grecaptcha.execute('6LcaVKAUAAAAAAfDTPgFv8tGuwrodh_Mv6_eJ0oT', {action: $(form).attr('id')}).then(function (captchaToken) {
                params['captcha_token'] = captchaToken;
                sendLeadAjaxRequest(form, params);
            });
        }
    );

}

export function sendLeadAjaxRequest(form, params) {
    $.ajax({
        url: Page.baseUrl + 'ajax/leads/create',
        type: 'POST',
        dataType: "json",
        data: params,
        success: function (response, textStatus, xhr) {

            let submitButton = $(form).find(':submit');
            submitButton.attr('value', submitButton.attr('original-value'));
            submitButton.attr('disabled', false);

            if (xhr.status == 201) {

                if(params.lead_type_code == 'tour'){
                    window.dataLayer?.push({'event': 'schedule_tour'});
                    $('#success_title').html("Tour Booking");
                    $('#success_message').html("Your tour booking has been sent!");
                    $('#success_start_date_readable').html(response.data.tour_start_date_readable);
                } else{
                    window.dataLayer?.push({'event': 'booking_enquiry'});
                    $('#success_title').html("Booking Enquiry");
                    $('#success_message').html("Your booking enquiry has been sent!");
                    $('#success_start_date_readable').html(response.data.start_date_readable);
                }

                $('#success_resource_type_readable').html(response.data.resource_type_name);
                $('#success_capacity_readable').html(response.data.capacity_readable);
                $('#success_duration_readable').html(response.data.duration_readable);
                $('.success_space_name').html(capitalize(response.data.space_name));
                $('#booking-sent-date').show();
                $('#success_space_message').show();
                $('#success_generic_message p').hide();
                $(form).parent().closest('div.modal').modal('hide');
                $('#booking-lead-success-popup').modal('show');

            }
            $('.lds-ring').css('display', 'none');

        }, error: function (jqXHR, exception) {
            let submitButton = $(form).find(':submit');
            submitButton.attr('value', submitButton.attr('original-value'));
            submitButton.attr('disabled', false);
            $('#error-popup').modal('show');
            $('.lds-ring').css('display', 'none');
        }
    });
}

export function getIdPrefix(form) {
    if (form.id == 'resource_availability_form') {
        return '#enquiry';
    } else if (form.id == 'general_form') {
            return '#general';
    }else if (form.id == 'post_requirements_form') {
        return '#post_requirements';
    } else if (form.id == 'tour_form') {
        return '#tour';
    } else if (form.id == 'buydaypass_step1_form') {
        return '#buydaypass';
    } else if (form.id == 'buydaypass_step2_form') {
        return '#buydaypass';
    }
}

export function validateEmail(email) {
    let re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export function validateEnglishName(val) {
    let reLetters = /^[A-Za-z]+$/;
    return reLetters.test(val);
}
