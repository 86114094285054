import 'intl-tel-input/build/css/intlTelInput.css';
import { requestIdleCallback } from '../../requestidlecallback';

let realIntlTelInput;
let loadCalled = false;
async function intlTelInput(a, b = undefined) {
    if (!loadCalled) {
        // Only load if the page gets interaction
        await new Promise((resolve) => {
            window.addEventListener('scroll', resolve, { once: true, passive: true });
            document.addEventListener('click', resolve, { once: true, passive: true });
            document.addEventListener('keydown', resolve, { once: true, passive: true });
        });
    }

    const intlTelInputLib = await loadLib();
    return intlTelInputLib(a, b);
}

async function loadLib() {
    if (realIntlTelInput != null) return realIntlTelInput;

    if (!loadCalled) {
        import('./intl-tel-input-flags');
        loadCalled = true;
    }

    const lib = await import('intl-tel-input');
    realIntlTelInput = lib.default;

    requestIdleCallback(() => {
        if (window.intlTelInputGlobals.startedLoadingUtilsScript) return;
        window.intlTelInputGlobals.startedLoadingUtilsScript = true;

        import('intl-tel-input/build/js/utils');
    });
    
    return realIntlTelInput;
}

export default intlTelInput;
