import intlTelInput from '../../_shared/vendor/intl-tel-input';
import { getIdPrefix, validateEmail } from './lead_popup_base';
import { selectRebuildOptions } from '../common';

const el = document.getElementById.bind(document);
$(function () {
    setupPostRequirementsForm();

    async function setupPostRequirementsForm() {
        selectRebuildOptions('post_requirements_country_dropdown', true);
        el('post_requirements_country').value = el(
            'post_requirements_country_dropdown'
        ).value;

        selectRebuildOptions('post_requirements_city_dropdown', true);
        el('post_requirements_city').value = el(
            'post_requirements_city_dropdown'
        ).value;

        $('#post_requirements_city_select li').on('click', function () {
            $('#post_requirements_city').val($(this).attr('rel'));
        });
        //Cookies.set('resource_type_code', 'value');
        // var userFirstName = Cookies.get('userFirstName');
        // var userLastName = Cookies.get('userLastName');
        // var userEmail = Cookies.get('user_email');
        // var userCompany = Cookies.get('user_company');
        // var userPhone = Cookies.get('user_phone');
        // var userStartDate = Cookies.get('user_start_date');
        // var resourceTypeCode = Cookies.get('resource_type_code');
        // var userCapacity = Cookies.get('user_capacity');
        // var durationQty = Cookies.get('duration_qty');
        // var durationMetric = Cookies.get('duration_metric');
        // var userMessage = Cookies.get('user_message');
        var resourceTypeCode;

        let form = $('#post_requirements_enquiry_submit_button').closest(
            'form'
        )[0];

        if (form.id == undefined || form.id.length == 0) {
            console.log(
                'General Enquiry form not setup up correctly. Invalid form type.'
            );
            alert(
                'An error took place while sending your request. Please contact support at hello@coworker.com.'
            );
            return;
        }

        $(form).find('.validation-error').hide();

        let idPrefix = getIdPrefix(form);

        // pre set form values
        $('.modal').on('show.bs.modal', function (event) {
            let phone = $(idPrefix + '_phone'),
                lPadd = phone.prev('.iti__flag-container').width() + 90;
            phone.css('padding-left', lPadd);
            resourceTypeCode = $(event.relatedTarget).data(
                'resource-type-code'
            );
            if (resourceTypeCode) {
                $(
                    '#post_requirements_resource_type_code_select select option[value=' +
                        resourceTypeCode +
                        ']'
                ).attr('selected', 'selected');
            }
        });

        // hide validation errors on close
        $('.modal').on('hidden.bs.modal', function () {
            $(this).find('.validation-error').hide();
        });

        // handle bootstrap dropdown menus to be able to collects selected values
        $('#post_requirements_country_select li').on('click', function () {
            $('#post_requirements_country').val($(this).attr('rel'));
        });
        $('#post_requirements_resource_type_code_select li').on(
            'click',
            function () {
                $('#post_requirements_resource_type_code').val(
                    $(this).attr('rel')
                );
            }
        );
        $('#post_requirements_duration_metric_select li').on(
            'click',
            function () {
                $('#post_requirements_duration_metric').val(
                    $(this).attr('rel')
                );
            }
        );
        $('#post_requirements_duration_qty_select li').on('click', function () {
            $('#post_requirements_duration_qty').val($(this).attr('rel'));
        });
        $('#post_requirements_capacity_select li').on('click', function () {
            $('#post_requirements_capacity').val($(this).attr('rel'));
        });

        var input = document.querySelector(idPrefix + '_phone');
        var phoneInput = await intlTelInput(input, {
            allowDropdown: true,
            separateDialCode: true,
            autoHideDialCode: false,
            autoPlaceholder: 'off',
            dropdownContainer: document.body,
            excludeCountries: [''],
            formatOnDisplay: true,
            geoIpLookup: function (callback) {
                $.get('https://ipinfo.io', function () {}, 'jsonp').always(
                    function (resp) {
                        let countryCode =
                            resp && resp.country ? resp.country : '';
                        let city = resp && resp.city ? resp.city : '';
                        let region = resp && resp.region ? resp.region : '';
                        let ip = resp && resp.ip ? resp.ip : '';
                        let latLng = resp && resp.loc ? resp.loc : '';
                        var latLngParts = latLng.split(',');
                        $(idPrefix + '_user_country_code').val(countryCode);
                        $(idPrefix + '_user_city').val(city);
                        $(idPrefix + '_user_region').val(region);
                        $(idPrefix + '_user_ip').val(ip);
                        $(idPrefix + '_user_lat').val(latLngParts[0]);
                        $(idPrefix + '_user_lng').val(latLngParts[1]);
                        callback(countryCode);
                    }
                );
            },
            hiddenInput: 'post_requirements_full_number',
            initialCountry: 'auto',
            localizedCountries: {},
            nationalMode: false,
            onlyCountries: [],
            placeholderNumberType: 'MOBILE',
            preferredCountries: [],
            separateDialCode: true,
        });

        // setup submit button
        $(form).on('submit', function (e) {
            e.preventDefault();
            form.phoneInput = phoneInput;
            $(form).find('.validation-error').hide();
            let submitButton = $(form).find(':submit');
            submitButton.attr('disabled', true);
            if (validateEnquiryForm(form)) {
                $('.lds-ring')[0].style.display = 'inline-block';
                submitButton.attr('original-value', submitButton.attr('value'));
                submitButton.attr('value', 'Processing...');
                let params = getGeneralEnquiryLeadFormData(form);
                sendLeadBooking(form, params);
            } else {
                submitButton.attr('value', submitButton.attr('original-value'));
                submitButton.attr('disabled', false);
            }
        });
    }

    function getGeneralEnquiryLeadFormData(form) {
        let idPrefix = getIdPrefix(form);
        let phone = form.phoneInput.getNumber(
            intlTelInputUtils.numberFormat.E164
        );
        let phoneCountryCode = form.phoneInput.getSelectedCountryData().iso2;

        return {
            lead_type_code: $(idPrefix + '_lead_type_code').val(),
            capture_page: $(idPrefix + '_capture_page').val(),
            space_id: $(idPrefix + '_space_id').val(),
            user_id: $(idPrefix + '_user_id').val(),
            user_first_name: $(idPrefix + '_first_name').val(),
            user_last_name: $(idPrefix + '_last_name').val(),
            user_last_name_: $(idPrefix + '_last_name_').val(),
            user_name:
                $(idPrefix + '_first_name').val() +
                ' ' +
                $(idPrefix + '_last_name').val(),
            user_email: $(idPrefix + '_email').val(),
            user_phone: phone,
            user_phone_country_code: phoneCountryCode,
            user_company: $(idPrefix + '_company_name').val(),
            user_message: JSON.stringify($(idPrefix + '_message').val()),
            user_country_code: $(idPrefix + '_user_country_code').val(),
            user_city: $(idPrefix + '_user_city').val(),
            user_region: $(idPrefix + '_user_region').val(),
            user_ip: $(idPrefix + '_user_ip').val(),
            user_lat: $(idPrefix + '_user_lat').val(),
            user_lng: $(idPrefix + '_user_lng').val(),
            start_date: $(idPrefix + '_start_date').val(),
            resource_type_code: $(idPrefix + '_resource_type_code').val(),
            city_id: $(idPrefix + '_city').val(),
            country_id: $(idPrefix + '_country').val(),
            duration_metric: $(idPrefix + '_duration_metric').val(),
            duration_qty: $(idPrefix + '_duration_qty').val(),
            capacity: $(idPrefix + '_capacity').val(),
        };
    }

    function validateEnquiryForm(form) {
        let hasErrors = false;
        let idPrefix = getIdPrefix(form);

        $(form)
            .find('input, select, textarea')
            .filter('[is_required]')
            .each(function (i, requiredField) {
                if ($.trim($(requiredField).val()) === '') {
                    $(requiredField).siblings('.validation-error').show();
                    hasErrors = true;
                }
            });

        let emailInput = $(idPrefix + '_email');
        if (emailInput.val().length > 0 && !validateEmail(emailInput.val())) {
            hasErrors = true;
            $(emailInput).siblings('.validation-error').show();
        }

        if (parseInt(Math.abs(form.phoneInput.getValidationError())) > 0) {
            hasErrors = true;
            $('#post_requirements_phone_error.validation-error').show();
        }

        if (!hasErrors) {
            return true;
        } else {
            return false;
        }
    }

    $('#post_requirements_country_select li').on('click', function () {
        let countryId = $(this).attr('rel');
        $('#post_requirements_country').val(countryId);
        $.getJSON('/ajax/cities/' + countryId, function (data) {
            populate_cities(data);
        });
    });

    function populate_cities(cities) {
        $('#post_requirements_city_dropdown')
            .empty()
            .append('<option selected="selected" value="">City</option>');
        if (cities) {
            const opts = document.createDocumentFragment();
            cities.forEach(function (chosen_country) {
                const opt = document.createElement('option');
                opt.value = chosen_country.id;
                opt.innerText = chosen_country.name;
                opts.appendChild(opt);
            });
            $('#post_requirements_city_dropdown').append(opts);
        }
        selectRebuildOptions('post_requirements_city_dropdown');

        $('#post_requirements_city_select li').on('click', function () {
            $('#post_requirements_city').val($(this).attr('rel'));
        });
    }

    function sendLeadBooking(form, params) {
        let submitButton = $(form).find(':submit');

        grecaptcha.ready(function () {
            grecaptcha
                .execute('6LcaVKAUAAAAAAfDTPgFv8tGuwrodh_Mv6_eJ0oT', {
                    action: $(form).attr('id'),
                })
                .then(function (captchaToken) {
                    params['captcha_token'] = captchaToken;
                    sendLeadAjaxRequest(form, params);
                });
        });
    }

    function sendLeadAjaxRequest(form, params) {
        $('.lds-ring')[0].style.display = 'inline-block';
        $.ajax({
            url: Page.baseUrl + 'ajax/leads/create',
            type: 'POST',
            dataType: 'json',
            data: params,
            success: function (response, textStatus, xhr) {
                let submitButton = $(form).find(':submit');
                submitButton.attr('value', submitButton.attr('original-value'));
                submitButton.attr('disabled', false);
                $('.lds-ring')[0].style.display = 'none';

                if (xhr.status == 201) {
                    $('#success_title').html('General Enquiry');
                    $('#success_message').html(
                        'Your Enquiry has been received!'
                    );
                    $('#booking-sent-date').html('');
                    $('#success_resource_type_readable').html(
                        response.data.resource_type_name
                    );
                    $('#success_capacity_readable').html(
                        response.data.capacity_readable
                    );
                    $('#success_duration_readable').html(
                        response.data.duration_readable
                    );
                    $('#booking-sent-date').hide();
                    $('#success_space_message').hide();
                    $('#success_generic_message p').html(
                        'One of our partnered brokers will reach out to you shortly to help assist you.'
                    );
                    $(form).parent().closest('div.modal').modal('hide');
                    $('#booking-lead-success-popup').modal('show');
                }
            },
            error: function (jqXHR, exception) {
                let submitButton = $(form).find(':submit');
                submitButton.attr('value', submitButton.attr('original-value'));
                submitButton.attr('disabled', false);
                $('#error-popup').modal('show');
                $('.lds-ring')[0].style.display = 'none';
            },
        });
    }
});
